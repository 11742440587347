.prompt {
    background: #212121;

    color: white;
    border-radius: 5px;
    width: 36rem;
}

.prompt-header {
    background: #262626;
    padding: 1.25rem;
    border-radius: 5px 5px 0 0;
}

.prompt-header p {
    margin: 0;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
}

.prompt-header svg {
    font-size: 20px;
}

.prompt-body {
    padding: 1.75rem;
}

.prompt-body p {
    margin-bottom: 1rem;
    font-size: 14px;
}

.prompt-body button {
    margin-left: 1rem;
    opacity: 0.7;
}

.prompt-body button:hover {
    opacity: 1;
}

button img {
    height: 24px;
    width: 24px;
}

button.button-tiny img {
    height: 20px;
    width: 20px;
}