@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Lato", sans-serif;
  /* background-color: '#F6FBFF'; */
}
/* =============== */

.queryBuilder{
  background-color:#f6f6f6; 
 }

 .rule {
  width: 75% !important;
  justify-content: space-evenly;
 }
 .single-select{
  width: 65% !important;
 }

 .rule-operators {
  width: 8% !important;
  margin: 0 .2rem ;
 }
 
 .ruleGroup{
   background-color: #f6f6f6 !important;
   border-style: solid;
   border-width: .1rem !important;
   border-color:  #EBEEF1 !important;
   padding-bottom: 3.5rem !important;
   padding-left: 1.2rem !important;
   border-radius: 0.1rem !important;
 }
 
 .rule-fields , .rule-operators , .rule-value{
   background-color: #f6f6f6;
 }
 
 .rule-fields{
   height: 2.5rem;
   font-size: 0.875rem !important;
   width: 17rem;
   border-radius: .2rem;
   border: .08rem solid #c5c5c5;
 }
 
 .rule-value{
  height: 2.5rem;
  font-size: 0.875rem !important;
   width: 14rem;
   border-radius: .2rem;
   border: 0.5px solid #c5c5c5;
 }
 
 .rule-value:focus-visible ,.rule-fields:focus-visible ,.rule-operators:focus-visible {
   outline:rgb(225, 225, 225) solid 1px;
   border: 1px solid #c5c5c5;
 }
 
 .ruleGroup-combinators:focus-visible{
 outline: none;
 }
 .rule-operators{
  height: 2.5rem;
  font-size: 0.875rem !important;
   width: 4rem;
   border-radius: 4px;
   border: 0.5px solid #c5c5c5;
 }
 
 .rule{
   background-color: white;
     width: fit-content;
     padding: 8px;
     border: 0.5px solid #acacac78;
     border-radius: 2px;
 }
 
 .ruleGroup-combinators{
   border: none;
   background-color: transparent;
   height: 2.5rem;
   font-size: 0.875rem !important;
   border-radius: 4px;
   position: absolute;
   top: 7px;
   left: -72px;
 }
 
 .rule-remove , .ruleGroup-remove{
   background-color: #f6f6f6;
   width: 2rem;
   height: 2rem;
   font-size: 0.875rem !important;
   border: 1px solid #c3c3c3a6;
   border-radius: 2px;
   font-size: 18px;
 }
 
 .ruleGroup-addRule{
   background-color: #f6f6f6;
   /* width: 2rem; */
   padding: 0 .5rem;
   height: 2.5rem;
   font-size: 0.875rem !important;
   border: 1px solid #c3c3c3a6;
   border-radius: 5px;
 }
 
 .ruleGroup-addGroup{
   background-color: #f6f6f6;
   /* width: 2rem; */
   height: 2.5rem;
   font-size: 0.875rem !important;
   border: 1px solid #c3c3c3a6;
   border-radius: 2px;
 }
 
 .query-wraper{
   position: relative;
 }
 
 .relative{
   position: relative;
 }
 
 .ruleGroup.relative{
   margin-left: 3.5rem;
   padding-bottom: 3rem;
 }
 
 
 .ruleGroup-header{
   background-color: white;
   width: fit-content;
   padding: 8px;
   border: 0.5px solid #acacac78;
   border-radius: 2px;
 }
 
 
 .ruleGroup.relative.darkerthree{
  margin-left: 1rem !important;
}
 
 .rule-value{
   width: auto;
   min-width: 14rem;
 }
 
 .rule-fields ,.rule-operators ,.ruleGroup-combinators {
   padding-left:1px ;
   padding-right: 24px;
   -webkit-appearance: none;
   -moz-appearance: none;
   background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iNDgiPjxwYXRoIGQ9Ik00ODAtODAgMjQwLTMyMGw0NC00NCAxOTYgMTk2IDE5Ni0xOTYgNDQgNDRMNDgwLTgwWk0yODQtNTk2bC00NC00NCAyNDAtMjQwIDI0MCAyNDAtNDQgNDQtMTk2LTE5Ni0xOTYgMTk2WiIvPjwvc3ZnPg==") !important;
   
   background-position: calc(100% - .5rem), 100% 0;
   background-size:  1.5em 1.5em;
   background-repeat: no-repeat;
 }
 .ruleGroup-combinators{
   width: 4.5rem;
 }
 
 .backwhite{
   -webkit-appearance: none;
   -moz-appearance: none;
   background-position: calc(100% - .5rem), 100% 0;
   background-size:  1.5em 1.5em;
   background-repeat: no-repeat;
   background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iNDgiPjxwYXRoIGQ9Ik00ODAtODAgMjQwLTMyMGw0NC00NCAxOTYgMTk2IDE5Ni0xOTYgNDQgNDRMNDgwLTgwWk0yODQtNTk2bC00NC00NCAyNDAtMjQwIDI0MCAyNDAtNDQgNDQtMTk2LTE5Ni0xOTYgMTk2WiIvPjwvc3ZnPg==") !important;
 }
    
   
   .ruleGroup-header.relative{
       margin-left: 3.5rem;
       padding-bottom: 3rem;
     }
     
     .ruleGroup-addGroup{
       position: absolute;
         bottom: 5px;
         left: 30px;
         border: none;
         color: rgb(0, 68, 255);
         cursor: pointer;
     }
 
     .rule-fields , .rule-operators ,.rule-value{
       padding-left:4px ;
     }
 
 .ruleGroup.relative{
   background-color: #e3e3e3d4 !important;
   padding-top:15px ;
   padding-bottom: 15px;
 }
 .ruleGroup.relative.darkerthree{
   background-color: #efefef !important;
 
 }
 
 .ruleGroup-addGroup{
   background: none;
 }
 
 .ruleGroup-addGroup[data-level="2"]{
   background-color: #cc2020d4 !important;
 
 }

 .react-select__control {
  background-color: #f6f6f6 !important;
  border: 0.08rem solid #c5c5c5 !important;
  font-size: 0.87rem;
  min-width: 11rem;
}

.react-select__menu {
  border-radius: 0.2rem;
}
.react-select__menu div {
  background-color: #fffdfd !important;
  font-size: 0.87rem !important;
}

.react-select__input:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.react-select__option {
  /* border: 0.08rem solid #c5c5c5; */
  /* margin: 5px; */
}
.react-select__option--is-selected {
  border: 0.08rem solid #2381E3;
  border-radius: 3px;
}


input[type='checkbox'].rule-value {
  width: 1rem;
  min-width: 1rem;
  max-width: 1rem;
  height: 1rem;
  appearance: none;
  background-origin:border-box ;
  border-radius: 0.25rem;
  --tw-text-opacity: 1;
  color: rgb(28 100 242/var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219/var(--tw-border-opacity));
  cursor: pointer;
}

input[type="checkbox"].rule-value:checked {
  background-color:rgb(28, 100, 242) !important;
}

/* =============== */

.custom__btn {
  @apply flex items-center justify-center px-1 text-sm text-center transition-all ease-in-out border rounded-sm outline-none border-borderGray hover:opacity-100 focus:opacity-90 disabled:cursor-not-allowed disabled:opacity-30 hover:cursor-pointer;
}
.custom__icon {
  @apply flex-shrink-0 w-4 h-4 transition duration-100 text-grayColor hover:text-blueColor group-hover:text-blueColor dark:text-gray-400 dark:group-hover:text-white;
}

.overlay {
  @apply absolute inset-0 z-50 flex items-center justify-center bg-neutral-900 bg-opacity-80 pb-14;
}

.overlay-2 {
  @apply bg-neutral-900 bg-opacity-80 z-50 absolute pb-14 top-0 bottom-0 left-[-15px] right-0 flex justify-center items-center;
}

.custom__checkbox {
  @apply w-4 h-4 text-blue-600 bg-transparent bg-gray-200 border-2 border-gray-300 rounded-sm cursor-pointer focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600;
}

@layer components {
  .btn {
    @apply flex items-center justify-center w-full pt-2 pb-2 pl-4 pr-4 space-x-2 font-medium text-center transition-all bg-blue-600 rounded-md cursor-pointer disabled:opacity-30 disabled:cursor-not-allowed hover:shadow-lg;
  }

  .custombtn {
    @apply flex items-center justify-center text-sm text-center transition-all ease-in-out rounded-lg outline-none focus:opacity-90 hover:opacity-100 disabled:opacity-30 disabled:cursor-not-allowed;
  }
}

.custom_tab {
  display: inline-flex;
  padding: 1rem;
  /* border-bottom: 0.125rem solid; */
  border-radius: 0.5rem 0.5rem 0 0;
  cursor: pointer;
}

.custom_tab:hover {
  border-color: transparent;
}

.custom_tab:focus {
  outline: none;
  box-shadow: 0 0 0 0.125rem rgba(66, 153, 225, 0.5);
}

.auth-bg {
  background-image: url("../src/components/Login/auth-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.9;
  /* .bg-overlay{
      opacity: 0.9;
  }
  @media (min-width: 768px){
      height: 100vh;
  } */
}

#global_sidebar {
  background-color: white;
  color: #1e212a;
  min-height: 100vh;
  height: 100%;
  width: 140px;
  border-right: 1px solid #d8d8d8;
}

.modal {
  max-height: 95vh;
}

.pathway-edit-modal {
  max-height: 79vh;
}
.pathway-new-modal {
  max-height: 66vh;
}
.state-new-modal {
  max-height: 65vh;
}
.state-edit-modal {
  max-height: 80vh;
}
.edit-condition-modal {
  max-height: 88vh;
}

.Link-new-modal {
  max-height: 60vh;
}


body {
  position: fixed;
  width: 100%;
  background-color: #f8f8f8;
}

.ql-editor {
  min-height: 200px;
}

.imessage p::before,
.imessage p::after {
  bottom: -0.1rem;
  content: "";
  height: 1rem;
  position: absolute;
}

p.from-me::before {
  border-bottom-left-radius: 0.8rem 0.7rem;
  border-right: 1rem solid #20df26;
  right: -0.35rem;
  transform: translate(0, -0.1rem);
}

p.from-me::after {
  background-color: #fff;
  border-bottom-left-radius: 0.5rem;
  right: -40px;
  transform: translate(-30px, -2px);
  width: 10px;
}

.map-container {
  width: 100%;
  height: 30vh;
}

.combobox-input {
  width: 100%;
  padding: 0.5rem;
}

/* .ruleGroup-addGroup {
  display: none;
} */

tr.row-worker:hover .checkbox-worker {
  /* display: block; */
  opacity: 1;
}

.checkbox-worker {
  opacity: 0;
}
.checkbox-worker:checked {
  opacity: 1;
}


.cover__image-container {
  width: 400px;
}

.cover-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.soft-hover {
  transition: box-shadow 0.3s ease;
}

.soft-hover:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.search-box {
  align-items: center;
  margin-bottom: 5px;
  height: 35px;
  width: 300px;
  outline: none;
  border: none;
  background-color: #ECF5FF;
}
.btn-drawer {
  border: none;
  color: #fff;
  background: linear-gradient(to left, #0081E3 50%, #0163ae 50%);
  background-size: 200% 100%;
  background-position: right bottom;
}
.btn-drawer:hover {
  transition: all 0.9s ease;
  background-position: left bottom;
}